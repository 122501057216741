import React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import { WagmiConfig, createClient, configureChains, defaultChains, chain } from 'wagmi';
import { getDefaultClient } from 'connectkit';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';

// global css
import './src/styles/main.scss';

const { provider } = configureChains(defaultChains, [
  publicProvider(),
  infuraProvider({ apiKey: process.env.GATSBY_GALAXY_INFURA_ID }),
]);

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  const client = createClient(
    getDefaultClient({
      appName: 'Galaxy NFT',
      autoConnect: true,
      provider,
    }),
  );
  return <WagmiConfig client={client}>{element}</WagmiConfig>;
};
